var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Input Mask" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeMask) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Credit Card", "label-for": "cardNumber" } },
                [
                  _c("cleave", {
                    staticClass: "form-control",
                    attrs: {
                      id: "cardNumber",
                      raw: false,
                      options: _vm.options.creditCard,
                      placeholder: "0000 0000 0000 0000"
                    },
                    model: {
                      value: _vm.form.cardNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "cardNumber", $$v)
                      },
                      expression: "form.cardNumber"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Phone Number", "label-for": "phone" } },
                [
                  _c(
                    "b-input-group",
                    [
                      _c(
                        "b-input-group-prepend",
                        { attrs: { "is-text": "" } },
                        [_vm._v(" US (+1) ")]
                      ),
                      _c("cleave", {
                        staticClass: "form-control",
                        attrs: {
                          id: "phone",
                          raw: false,
                          options: _vm.options.phone,
                          placeholder: "1234 567 8900"
                        },
                        model: {
                          value: _vm.form.phone,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "phone", $$v)
                          },
                          expression: "form.phone"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Date", "label-for": "date" } },
                [
                  _c("cleave", {
                    staticClass: "form-control",
                    attrs: {
                      id: "date",
                      raw: false,
                      options: _vm.options.date,
                      placeholder: "YYYY-MM-DD"
                    },
                    model: {
                      value: _vm.form.date,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "date", $$v)
                      },
                      expression: "form.date"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Time", "label-for": "time" } },
                [
                  _c("cleave", {
                    staticClass: "form-control",
                    attrs: {
                      id: "time",
                      raw: false,
                      options: _vm.options.time,
                      placeholder: "hh:mm:ss"
                    },
                    model: {
                      value: _vm.form.time,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "time", $$v)
                      },
                      expression: "form.time"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6", xl: "4" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: { label: "Numeral Formatting", "label-for": "number" }
                },
                [
                  _c("cleave", {
                    staticClass: "form-control",
                    attrs: {
                      id: "number",
                      raw: false,
                      options: _vm.options.number,
                      placeholder: "10,000"
                    },
                    model: {
                      value: _vm.form.number,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "number", $$v)
                      },
                      expression: "form.number"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Blocks", "label-for": "block" } },
                [
                  _c("cleave", {
                    staticClass: "form-control",
                    attrs: {
                      id: "block",
                      raw: false,
                      options: _vm.options.block,
                      placeholder: "Block [4,3,3]"
                    },
                    model: {
                      value: _vm.form.block,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "block", $$v)
                      },
                      expression: "form.block"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Delimiters", "label-for": "delimiter" } },
                [
                  _c("cleave", {
                    staticClass: "form-control",
                    attrs: {
                      id: "delimiter",
                      raw: false,
                      options: _vm.options.delimiter,
                      placeholder: "Delimiter: '.'"
                    },
                    model: {
                      value: _vm.form.delimiter,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "delimiter", $$v)
                      },
                      expression: "form.delimiter"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6", xl: "4" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Custom Delimiters",
                    "label-for": "customDelimiter"
                  }
                },
                [
                  _c("cleave", {
                    staticClass: "form-control",
                    attrs: {
                      id: "customDelimiter",
                      raw: false,
                      options: _vm.options.customDelimiter,
                      placeholder: "Delimiter: ['.', '.', '-']"
                    },
                    model: {
                      value: _vm.form.customDelimiter,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "customDelimiter", $$v)
                      },
                      expression: "form.customDelimiter"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Prefix", "label-for": "prefix" } },
                [
                  _c("cleave", {
                    staticClass: "form-control",
                    attrs: {
                      id: "prefix",
                      raw: false,
                      options: _vm.options.prefix
                    },
                    model: {
                      value: _vm.form.prefix,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "prefix", $$v)
                      },
                      expression: "form.prefix"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }